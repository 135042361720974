<template>
  <div
    class="fixed inset-y-0 left-0 w-48 xl:w-52 transition duration-300 transform gradient-bg overflow-y-auto lg:translate-x-0 lg:static lg:inset-0">
    <div class="pt-6">
      <div class="flex flex-col items-center p-4">
      <img src="@/assets/logo-white.png" alt="Logo" class="h-8 mr-2" />
      <h1 class="text-4 font-bold text-center text-white mb-6">Anchorage MFB</h1>
    </div>
      <div class="flex flex-col items-center mb-8">
        <div class="bg-blue-600 rounded-full w-16 h-16 flex items-center justify-center text-4xl text-white">
          {{ subName }}
        </div>
        <div class="pt-4">
          <p class="flex items-center justify-center text-2xl font-semibold text-white">{{ name }}</p>
        </div>
      </div>
      <router-link to="/dashboard" class="flex items-center px-8">
        <i class="pi pi-th-large mr-3"></i>
        <span class="text-white text-sm">Home</span>
      </router-link>

      <!-- Loans Dropdown -->
      <router-link to="#" class="flex items-center pl-8 my-8">
        <div class="relative">
          <p @click.prevent="toggle" class="flex flex-row items-center bg-transparent focus:outline-none">
            <i class="pi pi-chart-line mr-3"></i>
            <span class="text-sm">Loans</span>
            <svg fill="currentColor" viewBox="0 0 20 20" :class="{ 'rotate-180': open, 'rotate-0': !open }"
              class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1">
              <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </p>
          <!-- Menus -->
          <div v-if="open" class="absolute w-28 top-2 mt-2 origin-top-right rounded-md z-50 shadow-md bg-blue-700">
            <div class="p-2">
              <router-link class="block text-white py-2 text-sm font-semibold w-full"
                to="/dashboard/manage-loans">
                <span class="text-xs">Manage Loans</span>
              </router-link>
              <router-link class="block text-white py-2 mt-2 text-xs font-semibold pr-2 w-full"
                to="/dashboard/loan-request">
                Loan Request
              </router-link>
            </div>
          </div>
        </div>
      </router-link>

      <!-- Organization -->
      <router-link to="/dashboard/organisation" class="flex items-center pl-8 my-8">
        <i class="pi pi-building mr-3"></i>
        <span class="text-white text-sm">Organization</span>
      </router-link>

      <!-- Users Dropdown -->
      <router-link to="#" class="flex items-center pl-8 my-8">
        <div class="relative">
          <p @click.prevent="_toggle" class="flex flex-row items-center bg-transparent focus:outline-none">
            <i class="pi pi-users mr-3"></i>
            <span class="text-sm">Users</span>
            <svg fill="currentColor" viewBox="0 0 20 20" :class="{ 'rotate-180': open1, 'rotate-0': !open1 }"
              class="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1">
              <path fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </p>
          <!-- Menus -->
          <div v-if="open1" class="absolute w-28 top-2 mt-2 origin-top-right rounded-md z-50 shadow-md bg-blue-700">
            <div class="p-2">
              <router-link class="flex text-white py-2 mt-2 text-xs font-semibold pr-5" to="/dashboard/customers">
                <span>Customers</span>
              </router-link>
              <router-link class="flex text-white py-2 mt-2 text-xs font-semibold pr-5" to="/dashboard/system-users">
                <span>System Users</span>
              </router-link>
              <router-link class="flex text-white py-2 mt-2 text-xs font-semibold pr-5" to="/dashboard/user-groups">
                <span>User Group</span>
              </router-link>
            </div>
          </div>
        </div>
      </router-link>
      <!-- <router-link to="/dashboard/system-log" class="flex items-center pl-8 my-8">
        <img src="@/assets/img/appointments.svg" alt="">
        <span class="text-white pl-4 text-sm">System Log</span>
      </router-link> -->

      <!-- Reports -->
      <router-link to="/dashboard/system-log" class="flex items-center pl-8 my-8">
        <i class="pi pi-cog mr-3"></i>
        <span class="text-white text-sm">Reports</span>
      </router-link>

      <!-- Logout -->
      <div @click.prevent="logout" class="flex items-center pl-8 cursor-pointer mt-20">
        <i class="pi pi-sign-out mr-3 text-white"></i>
        <span class="text-white text-sm">Logout</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      open1: false,
      name: "",
      subName: "",
    }
  },
  mounted() {
    this.getUserName();
  },
  methods: {
    getUserName() {
      this.loader = true;
      this.$http.get("/admin/users/me").then((res) => {
        this.name = res.data.data.name;
        this.subName = this.name.substring(0, 1);
      }).catch((err) => {
        console.log(err);
      });
    },
    toggle() {
      this.open = !this.open
    },
    _toggle() {
      this.open1 = !this.open1
    },
    logout() {
      localStorage.clear();
      this.$router.push({ name: "login" })
    }
  }
}
</script>

<style scoped>
.gradient-bg {
  background: linear-gradient(135deg, #0058F1, #034ecf, #1749a0);
}

a {
  color: white !important;
}

a.router-link-exact-active {
  /* height: 2rem; */
  color: #ffffff !important;
  /* background: #0045bd; */
  border-left: 4px solid #ffffff;
  font-weight: 900 !important;
}
</style>