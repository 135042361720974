<template>
  <div
    class="bg-transparent flex flex-col md:flex-row justify-between items-start md:items-center py-5 mx-2 border-b border-line ">
    <div>
      <h3 class="font-bold text-4xl pb-4">{{ header }}</h3>
      <span class="text-sub text-sm absolute -mt-3">{{ subHeader }}</span>
    </div>
    <div class="flex justify-between items-center">
      <div class="px-8">
        <button class="bg-blue-800 rounded w-36 h-11 text-center account text-white focus:outline-none">
          Account Settings
        </button>
      </div>
      <div class="relative pr-4">
        <i class="pi pi-bell text-3xl"></i>
        <div
          class="absolute top-0 right-4 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
          2
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routePath: "",
      loader: false,
      header: "",
      subHeader: "",
      name: "",
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(x) {
        switch (x.name) {
          case "dashboard-home":
            this.header = "Dashboard";
            this.subHeader = "Welcome Back";
            break;
          case "manage-loans":
            this.header = "Manage Loans";
            this.subHeader = "Set up and modify loans";
            break;
          case "loan-request":
            this.header = "Loans Request";
            this.subHeader = "Have a view of all Loan requests";
            break;
          case "view-customer":
            this.header = "Loans Request";
            this.subHeader = "Have a view of all Loan requests";
            break;
          case "organisation":
            this.header = "Organisation";
            this.subHeader = "Manage all pre-approved organizations";
            break;
          case "customers":
            this.header = "Customers";
            this.subHeader = "Keep track of all Customers!";
            break;
          case "view-user":
            this.header = "Customers";
            this.subHeader = "Keep track of all Customers!";
            break;
          case "system-users":
            this.header = "System Users";
            this.subHeader = "Keep track of all System Users!";
            break;
          case "user-groups":
            this.header = "User Groups";
            this.subHeader = "Keep track of all System Users!";
            break;
          case "system-log":
            this.header = "System Logs";
            this.subHeader = "Monitor all activity that takes place on the platform";
            break;
          case "view-organisation":
            this.header = "Organisation";
            this.subHeader = "Manage all pre-approved organizations";
            break;
          default:
            break;
        }
      },
    },
  },
};
</script>

<style scoped>
.account {
  font-family: "Lato", sans-serif;
  background-color: #0058F1;
}
</style>
